.c_programList
{
    width: 100%;
    margin-top: 40px;

    color: white;
    
    > div.date
    {
         > div
        {
            font-family: Lato;
            font-size: 20px;
            font-weight: 300;

            padding-left: 15px;

            color: #ffb742;
            border-left: 5px solid #ffb742;
        }
    }

    > div.tabs
    {
        font-family: Lato;
        font-size: 24px;

        margin-top: 20px;

        color: #9b9b9b;

        .active
        {
            transition: color .5s;

            color: #f4cc8b;
            border-bottom: 1.5px solid #f4cc8b;
        }
        .now
        {
            transition: color .5s;

            color: #ffb742;    
        }


         > div
        {
            display: flex;

             > div
            {
                flex: 1;

                padding-bottom: 12px;

                text-align: center;

                border-bottom: 1.5px solid #4a4a4a;

                @media (max-width: 480px)
                {
                    &.hidden
                    {
                        display: none;
                    }
                }

                &:hover
                {
                    cursor: pointer;
                }

                 > div
                {
                     > span
                    {
                        font-size: 17px;

                        margin-left: 2px;
                    }
                }
            }
        }
    }

     > div.programList
    {
        position: relative;
        display: flex;
        flex-direction: column;

        height: 45vh;
        padding: 5%;

        color: #fff;

         > div.programContainer
        {
            overflow: scroll;
            height: 100%;
            
            &:after
            {
                position: absolute;
                top: 0;
                left: 0;

                display: block;

                width: 100%;
                height: 100%;

                content: '';

                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, .9) 90%);
            }

             > div
            {
                font-weight: 300;

                display: flex;
                align-items: center;

                padding-top: 12px;
                padding-bottom: 12px;

                border-bottom: 1px solid #4a4a4a;

                

                 > div
                {
                    &:first-child
                    {
                        flex: 1.3;

                        @media (min-width: 481px) and (max-width: 1024px)
                        {
                            flex: 1;
                        }
                    }


                    &:nth-child(2)
                    {
                        font-size: 14px;
                        font-weight: 300;

                        flex: 1.3;

                        padding: 3px 2px;

                        text-align: center;

                        @media (min-width: 768px) and (max-width: 1024px)
                        {
                            flex: 1.7;
                        }

                        @media (min-width: 481px) and (max-width: 767px)
                        {
                            flex: 2;
                        }

                        @media (max-width: 480px)
                        {
                            display: none;
                        }

                         > div
                        {
                            font-weight: 300;

                            max-width: 90px;
                            padding: 5px 5px;

                            color: #000;
                            border-radius: 20px;
                            background-color: #cecece;
                        }
                    }

                    &:last-child
                    {
                        flex: 6;

                        @media (min-width: 768px) and (max-width: 1024px)
                        {
                            flex: 5.5;
                        }

                        @media (min-width: 481px) and (max-width: 767px)
                        {
                            flex: 5;
                        }
                    }
                }
            }
        }
    }
}
