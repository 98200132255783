.c_about
{
     > div
    {
        &:first-child
        {
            padding-bottom: 20px;

            border-bottom: 1px solid #4a4a4a;
        }

         > div.title
        {
            font-family: Lato;
            font-size: 20px;
            font-weight: 300;

            display: inline-block;

            padding-left: 15px;

            text-align: justify;

            color: #ffb742;
            border-left: 5px solid #ffb742;
        }
    }

    section
    {
        font-family: PingFangTC;

        display: flex;

        color: #ffb742;

        @media (max-width: 480px)
        {
            flex-direction: column;
        }

         > div.part
        {
            display: flex;
            flex: 1;
            flex-direction: column;

            color: #fff;

            @media (max-width: 480px)
            {
                flex-direction: column;
            }

             > div.rectangle
            {
                flex: 1;

                padding: 8% 8% 4% 8%;

                @media (max-width: 480px)
                {
                    width: 100%;
                    padding: 6%;
                }

                 > div.subTitle
                {
                    font-size: 18px;
                    font-weight: 500;

                    display: inline-block;

                    margin-left: 15px;

                    text-align: justify;
                }

                 > div.content
                {
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 1.8;

                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    margin-top: 20px;

                    text-align: center;
                    text-align: justify;

                     > img
                    {
                        width: 85%;
                    }

                     > div
                    {
                        font-family: PingFangTC;
                        font-size: 16px;

                        margin-top: 5px;

                        text-align: center;

                        color: #f5a623;
                    }
                }

                 > div.rect
                {
                    position: relative;
                    top: -4px;

                    display: inline-block;

                    width: 13px;
                    height: 6px;

                    background-color: #fff;
                }
            }
        }
    }
}
