footer
{
    display: block;
    height: 60px;

    @media (max-width: 767px)
    {
        height: 90px;
    }

     > div
    {
        display: flex;
        align-items: center;

        height: 100%;

        border-right: 1px solid #4a4a4a;
        border-left: 1px solid #4a4a4a;

        @media (max-width: 767px)
        {
            border: 0;
        }

         > div
        {
            display: flex;
            align-items: center;

            width: 100%;
            height: 100%;
            margin-right: 5%;
            margin-left: 5%;

            border-top: 1px solid #4a4a4a;

            @media (max-width: 854px)
            {
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }

            @media (max-width: 767px)
            {
                margin: 0;
            }

            @media (max-width: 480px)
            {
                margin-left: 10%;
                margin-right: 10%;
            }

             > div
            {
                font-weight: 300;
                font-size: 14px;

                color: #fff;
                line-height: 18px;
                font-family: NotoSansCJKtc;

                &:first-child
                {
                    font-size: 22px;

                    margin-right: 2%;

                    letter-spacing: 3px;

                    @media (max-width: 767px)
                    {
                        margin-bottom: 2%;
                    }
                }
            }
        }
    }
}
