@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);

$bgcolor: black;

body
{
    font-family: 'Noto Sans TC', 'Lato', PingFangTC, Microsoft JhengHei, sans-serif;
    background-color: $bgcolor;
    *
    {
        box-sizing: border-box;
    }

    div.fade
    {
        position: fixed;
        z-index: 100;

        width: 100%;
        height: 100%;

        transition: opacity 500ms, z-index 501ms;

        opacity: 1;
        background-color: #fff;

        &.hide
        {
            z-index: -100;

            opacity: 0;
        }
    }

    header,
    footer
    {
         > div
        {
            width: 80%;
            margin: 0 auto;

            @media (max-width: 480px)
            {
                width: 98%;
            }
        }
    }

    div[class^='co_']
    {
        min-height: 70vh;

         > div
        {
            height: 100%;
        }
    }

    .bg
    {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    div.feature
    {
        padding: 13%;
    }

    h1
    {
        margin: 20px 5px;
    }
}

@keyframes fadein
{
    from
    {
        opacity: 0;
    }

    to
    {
        opacity: 1;
    }
}

@keyframes fadeout
{
    from
    {
        opacity: 1;
    }

    to
    {
        opacity: 0;
    }
}

.container {
    width: 80%;
    margin: 0 auto;
    border-left: 1px solid #4a4a4a;
    border-right: 1px solid #4a4a4a;
}
::-webkit-scrollbar {
    display: none;
  }
